@import "./mixins.scss";

$primary: #a224e9;
$secondary: #64a45a;

$white: #ffffff;
$black: #000000;
$grey: #8f8f8f;

$default: #eff1ef;
$font-color: #3a3a3c;
$font-color-light: #b3b3b3;
$border-light: #ebebeb;
$border: #d9d9d9;
$background: $white;

$font-white: $white;

$twitter: #76a9ea;
$facebook: #475993;
$linkedin: #0077b5;

$black-overlay-dark: rgba($black, 0.75);
$black-overlay-light: rgba($black, 0.2);

//----------- dashboard layout  specific color ----------

$dashboard-header-bg: $white;
$dashboard-header-font-light: #e6e6e6;
$dashboard-header-font: #999999;
$dashboard-header-font-dark: $font-color;

$dashboard-sider-bg: $white;
$dashboard-sider-font: #999999;
$dashboard-sider-font-light: #e6e6e6;

//-------------- chatinterface specific color ---------------

$chatinterface-header-bg: $white;
$chatinterface-footer-bg: $white;

$red: #ff0000 !default;
$yellow: #ffcb00 !default;
$green: #4cb25c !default;
$info: #4da1ff !default;
$success: #90b900 !default;
$warning: #f5a623 !default;
$danger: #ff6d4a !default;
$magenta: #ff00ff !default;
$violet: #9400d3 !default;
$purple: #aa62e3;
$aquamarine: #29bb9c;

$primary-bg-light: tint($primary, 85%);
$green-bg-light: tint($green, 85%);
$warning-bg-light: tint($warning, 85%);
$default-bg-light: tint($default, 35%);
